<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-13 15:12:25
 * @ Description: Fixer's privacy policy page in landing section of the app.
 -->

<template>
    <div>
        <v-row
            style="padding-top: 90px; padding-bottom: 5rem;"
            justify="center"
        >
            <!-- Heading -->
            <v-col
                class="text-center pb-6"
                :class="$vuetify.breakpoint.mdAndUp ? 'mont-50-eb' : 'mont-40-eb'"
                cols="12"
                style="color: #2E567A;"
            >
                Privacy Policy
            </v-col>
            <!-- Footer -->

            <!-- Privacy Policy -->
            <v-col
                cols="12"
                class="os-14-r elevation-4 pa-2 rounded-lg"
                style="background-color: rgb(249, 251, 253);"
                :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : $vuetify.breakpoint.xs ? 'max-width: 500px;' : $vuetify.breakpoint.md ? 'max-width: 920px;' : 'max-width: 1110px;'"
            >
                <p><strong>PRIVACY POLICY</strong></p>
                <p><strong>Fixer Global Service</strong></p>

                <p><strong>1 INTRODUCTION</strong></p>


                <p>
                    This Privacy Policy applies whenever you access any of Fixer Global Services. Any terms not defined in this Privacy Policy shall be given the meaning allocated to them in the Terms of Service.
                    Please read this Privacy Policy before using Fixer Global Services. Fixer Global respects your privacy and endeavors to safeguard your privacy when you use Fixer Global Services.
                    By using Fixer Global Services, you express your agreement to this Privacy Policy. If you do not agree to the terms of this Privacy Policy, please do not use Fixer Global Services.
                    This Privacy Policy describes:
                </p>

                <p>
                    &bull; What is the legal basis for personal data processing <br />
                    &bull; What personal data we collect, how we collect it, and why <br />
                    &bull; How we use such personal data and with whom we share it <br />
                    &bull; Your rights: How you can access, manage and update your personal data <br />
                    &bull; The choices you can make about how we collect, use, and share your personal data <br />
                    &bull; How we protect your personal data <br />
                </p>

                <p>If you do not want us to collect, store, use or share your personal data in the ways described in this Privacy Policy, you may not use Fixer Global Services.</p>

                <p><strong>2 DATA CONTROLLERS</strong></p>

                <p>
                    The policies described in this document is the collection of Data Controller sub-headings that Fixer Global Limited adheres to with regards to the different types of data processing subjects. <br /> <br />
                    Fixer Global Limited
                </p>

                Business-ID: GB20025831 <br /> <br />
                
                <p><strong>3 LEGAL BASES FOR PERSONAL DATA PROCESSING</strong></p>

                <p><strong>3.1 Contract</strong></p>

                <p>
                    We process personal data for the performance of the contract between you and us when you have agreed to the Terms of Service and have begun to use the Fixer Global Services.
                    In addition, we process personal data in order to take steps at your request prior to entering into contract defined above.
                </p>

                <p><strong>3.2 Legitimate interest</strong></p>

                <p>Part of our processing is necessary for the purposes of our legitimate interest. Our legitimate interests allow us to process personal data for other reasons than merely for the performance of the contract between us and you. We may for example develop, monitor and analyze Fixer Global Services as well as send communication and marketing for you based on our legitimate interest. You may reach our contact person defined above if you consider that your interests or fundamental rights and freedoms override our legitimate interest and require protection of your personal data.</p>

                <p><strong>3.3 Consent</strong></p>

                <p>
                    We process personal data on the basis of your consent when you consent to receiving electronic direct marketing from us or when you consent to the use of cookies and other tracking technologies.
                    You will always have the right to withdraw your consent at any time by contacting our contact person or by clicking the respective link provided in our marketing messages. Such withdrawal does not, however, affect the lawfulness of processing based on consent before the withdrawal.
                </p>

                <p><strong>4 DATA WE COLLECT AND HOW WE COLLECT IT</strong></p>

                <p><strong>4.1 General</strong></p>

                <p>
                    We will only collect personal data to the extent necessary for providing our Fixer Global Services. Personal data may be collected, combined and used for the purposes of creating user accounts, user profiles, using, maintaining and developing Fixer Global Services, contacting users, providing services and products, sending customer information) to users, enabling use of Fixer Global Services and communication in Fixer Global Services and between users, conducting research and creating reports for internal use.
                </p>

                <p><strong>4.2 User Data</strong></p>
                
                
                <p>When you create your Account and use Fixer Global Services, you may give us data directly and we will store such User Data on our systems and use it for the purposes described in this Privacy Policy. Your Account-specific information may include:</p>

                <p>
                    &bull; your first and last names; <br />
                    &bull; your organization; <br />
                    &bull; your e-mail address; <br />
                    &bull; your physical address; <br />
                    &bull; a password; <br />
                    &bull; an Account number that is created in Fixer Global Services and used to identify your profile; and <br />
                    &bull; other information that helps us identify you when you access your Account or helps us improve Fixer Global Services. <br />
                    &bull; Bank details for payouts <br />
                </p>

                <p>You may choose to disclose information to other users, and any such information is considered public information, and there is no expectation of privacy. We are not responsible for any personal data you choose to make public in Fixer Global Services.</p>

                <p><strong>4.3 User Content</strong></p>

                <p>
                    The content you create or link to the Fixer Global Services will be available to your Organization. We take no responsibility for the accuracy or correctness of the User Content that you or other users within your Organization may submit within the Fixer Global Services. <br />
                    Each user shall ensure that he/she has a right to submit User Content and we will upon request remove any such User Content that is claimed to infringe any rights of a third party.
                </p>

                <p><strong>4.4 Cookies and Automated Information Collection</strong></p>

                <p>We and service providers acting on our behalf, such as Google Analytics, may store log files and use tracking technologies such as: cookies, which are small pieces of data transferred to your computer for record-keeping purposes; and local shared objects, also known as flash cookies, which help us to reduce fraud, remember your preferences and speed up loading times.</p>
                <p>We and our service providers may use these log files, tags, and tracking technologies to collect and analyze certain kinds of technical data, including:</p>

                <p>
                    &bull; IP addresses; <br />
                    &bull; the type of computer or device you are using; <br />
                    &bull; your operating system version; <br />
                    &bull; your mobile device's identifiers, like your MAC Address, Identifier For Advertising (IDFA), and/or International Mobile Equipment Identity (IMEI); <br />
                    &bull; your browser types; <br />
                    &bull; your browser language; <br />
                    &bull; referring and exit pages, and URLs; <br />
                    &bull; platform type; and <br />
                    &bull; the amount of time spent on particular pages. <br />
                </p>

                <p>We will connect this data from your Account.</p>

                <p><strong>4.5 Customer Support Correspondence</strong></p>

                <p>When you ask for help from our customer support team, we will collect and store the contact information you give them (generally, Zelmi Greyling and e-mail address), information about your activity on Fixer Global Services, and your Account details. We may also store the communications you have with the customer support team and any information in those communications in order to provide support and improve Fixer Global Services.</p>

                <p><strong>5 HOW WE USE THE DATA WE COLLECT</strong></p>

                <p>The main use of the information we collect and store is to provide a better service experience, but there are other uses as well. The purposes for which we may collect and store your personal data include:</p>

                <p>
                    &bull; to provide Future Platform Services; <br />
                    &bull; to provide technical support and respond to user inquiries; <br />
                    &bull; to monitor and analyze the use of Fixer Global Services; <br />
                    &bull; to protect the safety and well-being of our users; <br />
                    &bull; to protect Fixer Global’s rights and property in connection with the Services; <br />
                    &bull; to prevent fraud or potentially illegal activities, and to enforce the Terms of Service; <br />
                    &bull; to notify users of updates, new products or promotional offers; <br />
                    &bull; to comply with our legal obligations, resolve any disputes We may have with you or other users, and to enforce our agreements with third parties; <br />
                    &bull; to send communications and direct marketing; and <br />
                    &bull; to conduct research. <br />
                </p>

                <p>One important use of your information is communication. If you have provided your email address to us, We will use it to respond to customer support inquiries, and keep you informed of your activity.</p>

                <p><strong>6 INFORMATION SHARED WITH YOUR ORGANIZATION</strong></p>

                <p>If your use of Fixer Global Services is based on a License by an Organization, your Organization has access to your personal data and User Content and may use it subject to the applicable laws and agreements from time to time within the scope of their License.</p>
                <p>When processing your personal data collected and processed in Fixer Global Services to which the Organization has access, the Organization acts as a Data Controller and you should direct any inquiries in respect of the use of your personal data by your Organization to the respective contacts of the Organization.</p>
                <p>We shall not be held accountable for any misuse of your personal data or the failure to protect it.</p>

                <p><strong>7 HOW WE SHARE YOUR PERSONAL DATA TO THIRD PARTIES</strong></p>

                <p><strong>7.1 General</strong></p>

                <p>We may disclose aggregated information (information about you and other users collectively that is not intended to specifically identify you) and other non- personal data about our users for industry analysis, demographic profiling, marketing, analytics, and other business purposes.</p>
                <p>Notwithstanding the foregoing, personal data may be disclosed to third parties if required under any applicable law or regulation or order by competent authorities, or in order to enforce the Terms of Service and to investigate possible infringing use of the Fixer Global Services.</p>
                <p>Your personal data, and the contents of all of your online communications in the Services and between you and us may be accessed and monitored by us or disclosed to a third party:</p>

                <p>
                    &bull; if we have a good faith belief that we have to disclose the information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements, or legal process (for example, a court order, search warrant or subpoena); <br />
                    &bull; to satisfy any laws or regulations that apply; <br />
                    &bull; where we believe that Fixer Global Services are being used in <br />
                    committing a crime, including to report such criminal activity or to share information with other companies and organizations for the purposes of fraud protection, credit risk reduction, and other security precautions;
                    &bull; when we have a good faith belief that there is an emergency that poses a threat to the health and/or safety of you, another person or the public generally; and <br />
                    &bull; to protect the rights or property of Fixer Global and applicable third parties, including to enforce the Terms of Service. <br />
                </p>

                <p><strong>7.2 Third-Party Service Providers</strong></p>
                <p>We may share your personal data with third-party companies to perform services on our behalf, such as analyzing data, hosting services or customer support and service. We contractually require these third-party service providers to maintain the confidentiality of the information we share with them, and we contractually require them to comply with applicable data protection laws as well as to not use your personal data for anything other than to provide services on our behalf.</p>

                <p><strong>8 YOUR RIGHTS: HOW TO ACCESS, UPDATE AND MANAGE YOUR INFORMATION</strong></p>

                <p>
                    You have a right to access, update and manage your own personal data under the applicable data protection laws. If you wish to exercise any of the rights defined below, please email us at admin@9to5fixer.com. If you want to review, delete, or change the personal data processed by the Organization, contact the Organization directly.
                    We may ask you to provide further information in order to identify you. We will respond to your requests within 72 hours.
                </p>

                <p><strong>8.1 Accessing and Updating Your Personal data</strong></p>

                <p>You are entitled to obtain confirmation on whether or not we process personal data about you. You may also have information on our means of processing as well as a copy of the personal data undergoing processing. We may charge a reasonable fee based on our administrative costs if you request further copies.</p>

                <p><strong>8.2 Right to rectify your data</strong></p>

                <p>You shall have the right to obtain from us without undue delay the rectification of inaccurate personal data concerning you. You may have your incomplete data completed if you provide us with additional information or statement.</p>

                <p><strong>8.3 Right to erasure</strong></p>

                <p>If you no longer want us to actively process your personal data, you can have us delete your personal data if:</p>

                <p>
                    &bull; your personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed; <br />
                    &bull; if you withdraw your consent; <br />
                    &bull; if you object to the processing and there are no overriding legitimate grounds for the processing; <br />
                    &bull; the personal data have been unlawfully processed; or <br />
                    &bull; the personal data have to be erased for compliance with a legal obligation in applicable law. <br />
                </p>
                
                <p>If you no longer want Fixer Global to make active use of your personal data, you may send an e-mail to admin@9to5fixer.com. Place "Delete My Account" in the subject line and include your first name, last name and e-mail address in the body of the e-mail. Please note that certain records, for example those relating to payments or customer service matters, will be held for legal and accounting purposes.</p>
                
                <p><strong>8.4 Right to object processing</strong></p>

                <p>You have the right to object at any time processing of your personal data, which is based on legitimate interest defined above, including profiling on grounds relating to your particular situation.</p>
                <p>We shall no longer process your personal data unless we demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms.</p>
                <p>You have always right to object direct marketing and processing of personal data for such purposes.</p>

                <p><strong>8.5 Right to restriction of processing</strong></p>
                <p>You have the right to obtain from us restriction of processing where one of the following applies:</p>
                <p>
                    &bull; the accuracy of the personal data is contested by you, for a period enabling us to verify the accuracy of the personal data; <br />
                    &bull; the processing is unlawful and you oppose the erasure of the personal data and request the restriction of their use instead; <br />
                    &bull; we no longer need the personal data for the purposes of the processing, but you require that we store your data for the establishment, exercise or defense of legal claims; or <br />
                    &bull; you have objected to processing pursuant and the verification whether our legitimate grounds override your rights and freedoms. <br />
                </p>

                <p><strong>8.6 Right to data portability</strong></p>

                <p>You have the right to receive the personal data concerning you which you have provided to us (for example by submitting your email address for direct marketing purposes) in a structured, commonly used and machine-readable format. You have the right to have the data transmitted to another controller if:</p>

                <p>
                    &bull; the processing is based on consent or on a contract; and <br />
                    &bull; the processing is carried out by automated means. <br />
                </p>

                <p><strong>8.7 The right to lodge a complaint to the supervisory authority</strong></p>

                <p>In case you suspect a breach of data protection legislation in processing of your personal data and the matter is not solved amicably between you and us by negotiating, you may contact the competent supervisory authority to lodge a complaint.</p>

                <p><strong>8.8 Opting Out of Cookie Tracking</strong></p>

                <p>
                    You can set your web browser to warn you about attempts to place cookies on your computer or limit the type of cookies you allow. Flash cookies operate differently than browser cookies and cookie management tools available in a web browser may not remove flash cookies. <br />
                    If you disable cookies, you may lose some of the features and functionality of Fixer Global Services because some cookies are necessary to track and enhance your activities.
                </p>

                <p><strong>9 OUR POLICIES CONCERNING CHILDREN</strong></p>

                <p>If we learn that we have inadvertently gathered personal data from a child under 15, we will take reasonable measures to promptly remove that data from our records. The customer needs to be honest about the data they provide to 9to5fixer.com.</p>

                <p><strong>10 PRIVACY POLICIES OF LINKED THIRD-PARTY SERVICES</strong></p>

                <p>Our websites and Fixer Global Services may contain links to third-party services, which are companies other than Fixer Global that may link to their own websites, online services or applications. We are not responsible for the privacy practices or the content of these third-party services. If you have any questions about how these third-party services use your information, you should review their policies and contact them directly.</p>
                

                <p><strong>11 TRANSFERS OUTSIDE OF THE EU AND THE EEA</strong></p>
                
                <p>We may transfer your personal data to affiliated entities or to other third parties outside the EU and the European Economic Area. Please note that these countries and jurisdictions may not have the same data protection laws as your own jurisdiction, and we take steps to ensure adequate safeguards are in place to enable transfer of information to these third countries.</p>
                <p>We ensure that a legal basis for such a transfer exists and that adequate protection for the personal data is provided as required by applicable law. We use standard contractual clauses approved by the European Commission and require that the receiving party uses appropriate technical and organizational information security measures. We also cooperate with companies that have committed to Privacy Shield.</p>

                <p><strong>12 HOW LONG WE KEEP YOUR INFORMATION</strong></p>

                <p>
                    We will not retain your personal data for longer than is necessary for the defined purpose of the processing of personal data or for legal requirements. For instance, we may retain some information for a few years after you have closed your Account if this is necessary to meet our legal obligations or to exercise, defend or establish legal rights. Certain records, for example those relating to payments, will be retained for accounting purposes for six years after the end of the year during which the financial year ended. Information that is not under legal obligation to be retained will be destroyed 2 years after the deletion of the account. <br />
                    The information retention by your Organization shall be subject to the respective retention policies of your Organization.
                </p>

                <p><strong>13 SECURITY OF YOUR INFORMATION</strong></p>

                <p>We implement technical and organizational security measures to help protect the security of your personal data both online and offline. We take steps to ensure that your personal data is treated securely and in accordance with this Privacy Policy. These measures vary based upon the sensitivity of your data. Your Account information is protected by the password you use to access your Account. It is important that you protect and maintain your Account's security and that you immediately tell us of any unauthorized use or suspected unauthorized use of your Account. If you forget the password to your Account, Fixer Global Services allow you to request that instructions be sent to you that explain how to reset your password.</p>
                <p>While we take precautions against possible security breaches of Fixer Global Services and our customer databases and records, no website or Internet transmission is completely secure. We cannot guarantee that unauthorized access, hacking, data loss, or other breaches will never occur, and we cannot guarantee the security of your information while it is being transmitted to Fixer Global Services. Any transmission is at your own risk. If you have questions about the security of our websites, please contact us at admin@9to5fixer.com.</p>

                <p><strong>14 CHANGES TO THE PRIVACY POLICY</strong></p>

                <p>If we decide to make material changes to this Privacy Policy, we will tell you and other users by placing a notice on www.9to5fixer.com, or by sending you a notice to the e-mail address we have on file for you prior to the change becoming effective. We may supplement this process by placing notices on other Fixer Global websites. You should periodically check www.9to5fixer.com and this Privacy Policy for updates.</p>

                <p><strong>15 CONTACT US</strong></p>
                <p>If you have any questions, comments or concerns regarding the Privacy Policy or practices, please send an e-mail to admin@9to5fixer.com.</p>

            </v-col>
            <!-- Privacy Policy -->
        </v-row>
    </div>
</template>
<script>
    export default {
        name: 'PrivacyPage'
    }
</script>